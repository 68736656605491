import { NgModule } from '@angular/core';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { MessagesModule } from 'primeng/messages';
import { TabViewModule } from 'primeng/tabview';
import { InputMaskModule } from 'primeng/inputmask';
import { BadgeModule } from 'primeng/badge';

const primengModule = [
  ToastModule,
  ConfirmDialogModule,
  ConfirmPopupModule,
  TooltipModule,
  CardModule,
  ButtonModule,
  CheckboxModule,
  AccordionModule,
  ProgressSpinnerModule,
  InputTextModule,
  PaginatorModule,
  SidebarModule,
  MenuModule,
  DynamicDialogModule,
  InputSwitchModule,
  CalendarModule,
  InputGroupAddonModule,
  InputGroupModule,
  FloatLabelModule,
  ProgressBarModule,
  SkeletonModule,
  MessagesModule,
  TabViewModule,
  InputMaskModule,
  BadgeModule,
];

@NgModule({
  declarations: [],
  imports: [...primengModule],
  providers: [DialogService],
  exports: [...primengModule],
})
export class PrimengModule {}
