import { AdminHttpService } from '@admin/services/admin-http.service';
import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractPaginatedComponent } from '@shared/components/abstract-paginated.component';
import {
  ColorBadgeStatusColor,
  ColorBadgeStatusComponent,
} from '@shared/components/color-badge-status/color-badge-status.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { StatusIndicatorComponent, StatusType } from '@shared/components/status-indicator/status-indicator.component';
import { Constants } from '@shared/constants/constants';
import { SameWidthColumnDirective } from '@shared/directives/same-width-column.directive';
import { IProcessingBatchService } from '@shared/interfaces/IbatchService.interface';
import { DialogResult } from '@shared/models/dialog-result.model';
import { PageChangeEvent } from '@shared/models/paginator.model';
import { ParkingTicket, ParkingTicketTableColumns } from '@shared/models/parking-ticket/parking-ticket.model';
import { ProcessingBatchPayment } from '@shared/models/parking-ticket/processing-batch-payment';
import { ProcessingBatch } from '@shared/models/parking-ticket/processing-batch.model';
import { FileService } from '@shared/services/file.service';
import { PaginationService } from '@shared/services/pagination.service';
import {
  ProcessingBatchHttpService,
  ProcessingBatchUtilsService,
} from '@shared/services/processing-batch-http.service';
import { DialogWidth, StiiltDialogService } from '@shared/services/stiilt-dialog.service';
import { ToastService } from '@shared/services/toast.service';
import { ParkingTicketPaymentStatus } from '@shared/types/parking-ticket-payment.status';
import { ProcessingBatchPaymentStatus } from '@shared/types/payment.status.enum';
import { ProcessingBatchPaymentTypeEnum } from '@shared/types/processing-batch-payment-type.enum';
import { isNil } from 'lodash';
import { MenuItem } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { catchError, combineLatest } from 'rxjs';
import { ExportCsvModalComponent } from './components/export-csv-modal/export-csv-modal.component';
import { ParkingTicketsDetailSideBarComponent } from './components/parking-tickets-list/parking-tickets-detail-side-bar.component';
import { PatchParkingTicketModalFormComponent } from './components/patch-parking-ticket/patch-parking-ticket-modal-form.component';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';
import { ButtonModule } from 'primeng/button';
import { AsyncPipe, CurrencyPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { NotSpecifiedPipe } from '@shared/pipes/not-specified.pipe';
import { PrimengModule } from '@shared/primeng.module';
import { EmptyStateComponent } from '@shared/components/empty-state/empty-state.component';
import { StripePayment } from '@shared/components/card-payment/stripe-payment.component';
import { StiiltTemplateDirective } from '@shared/directives/stiilt-template.directive';
import { PayLaterModalComponent } from '@shared/components/batch-list/batch-detail/components/pay-later-modal/pay-later-modal.component';
import { CsvExport } from '@shared/models/processing-batch/csv-export.model';
import { Organisation } from '@pages/account/models/organisation.model';
import { ProfileHttpService } from '@pages/account/services/profile-http.service';
import { PaymentMethodType } from '@pages/account/types/checkout-session-payment-methods.type';
import moment from 'moment-timezone';

@UntilDestroy()
@Component({
  selector: 'stiilt-user-batch-detail',
  standalone: true,
  imports: [
    PrimengModule,
    TableModule,
    SameWidthColumnDirective,
    TranslocoPipe,
    StatusIndicatorComponent,
    ColorBadgeStatusComponent,
    TranslocoDatePipe,
    ButtonModule,
    NgIf,
    CurrencyPipe,
    ParkingTicketsDetailSideBarComponent,
    NotSpecifiedPipe,
    AsyncPipe,
    NgClass,
    EmptyStateComponent,
    StripePayment,
    SpinnerComponent,
    NgForOf,
    NgTemplateOutlet,
  ],
  providers: [StiiltDialogService],
  templateUrl: './batch-detail.component.html',
  styles: ``,
})
export class BatchDetailComponent
  extends AbstractPaginatedComponent<ParkingTicket>
  implements OnInit, AfterContentInit
{
  @Input() public isAdmin: boolean = false;
  @ContentChildren(StiiltTemplateDirective) private templates!: QueryList<StiiltTemplateDirective>;

  protected readonly ParkingTicketTableColumns = ParkingTicketTableColumns;
  protected readonly StatusType = StatusType;
  protected readonly ColorBadgeStatusColor = ColorBadgeStatusColor;
  protected readonly ParkingTicketStates = BatchStates;
  protected readonly ParkingTicketPaymentStatus = ParkingTicketPaymentStatus;

  private batchDetailService!: IProcessingBatchService;
  public clickedParkingTicket!: ParkingTicket | undefined;

  public totalAmount!: number;

  public batchId!: number;
  public processingBatch: ProcessingBatch | undefined;
  public items!: MenuItem[];
  public sidebarVisible: boolean = false;
  public clientSecret: string | undefined;
  public waitingAfterPayment: boolean = false;
  public isClientSecretError: boolean = false;
  public batchPayment: ProcessingBatchPayment | undefined;
  public currentBatchState!: BatchStates;
  public organisation: Organisation | undefined;
  public isBankTransfer = false;

  public headerTemplate!: TemplateRef<unknown>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly processingBatchUtilsService: ProcessingBatchUtilsService,
    private readonly stiiltDialogService: StiiltDialogService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslocoService,
    private readonly filePathService: FileService,
    private readonly processingBatchService: ProcessingBatchHttpService,
    private readonly adminService: AdminHttpService,
    private readonly router: Router,
    protected override readonly paginationService: PaginationService,
    private readonly profileHttpService: ProfileHttpService,
  ) {
    super();
    this.tableColumns = [
      {
        name: 'current_batch.table_columns.licence_plate',
        field: ParkingTicketTableColumns.licencePlate,
      },
      {
        name: 'current_batch.table_columns.payment_notice',
        field: ParkingTicketTableColumns.paymentNotice,
      },
      {
        name: 'current_batch.table_columns.amount',
        field: ParkingTicketTableColumns.amount,
      },
      {
        name: 'current_batch.table_columns.offence_date',
        field: ParkingTicketTableColumns.offenceDate,
      },
      {
        name: 'current_batch.table_columns.vehicle_brand',
        field: ParkingTicketTableColumns.vehicleBrand,
      },
      {
        name: '',
        field: ParkingTicketTableColumns.actions,
      },
    ];
  }

  ngOnInit(): void {
    this.batchDetailService = this.isAdmin ? this.adminService : this.processingBatchService;
    this.batchDetailService.parkingTickets$.pipe(untilDestroyed(this)).subscribe((response) => {
      this.entities = response.data;
      this.totalCount = response.totalCount;
      this.isLoading = false;
      this.page = response.page;
      this.size = response.size;
      this.totalAmount = response.totalAmount;
      this.unfilteredTotalCount = response.unfilteredTotalCount;
    });

    combineLatest([this.batchDetailService.selectedProcessingBatch$, this.profileHttpService.userProfile$])
      .pipe(untilDestroyed(this))
      .subscribe(([processingBatch, userProfile]) => {
        this.organisation = userProfile.organisation;
        this.isBankTransfer = this.organisation?.availablePaymentMethods.includes(PaymentMethodType.BANK_TRANSFER);

        this.processingBatch = processingBatch;
        if (this.processingBatch) {
          this.batchPayment = this.getProcessingBatchPayment();
          this.batchId = this.processingBatch.id;
          this.setCurrentBatchState();
          if (this.canDisplayParkingTickets) {
            this.getParkingTicketsForBatch();
          } else {
            this.isLoading = false;
          }
        }
        this.retrieveClientSecretIfApplicable();
      });

    this.route.params.subscribe((params: any) => {
      if (params) {
        this.page = Constants.DEFAULT_FIRST_PAGE_NUMBER;
        this.size = Constants.DEFAULT_PAGE_SIZE;
        this.batchId = params.id;
        this.isLoading = true;

        this.processingBatch = undefined;
        this.batchDetailService.getProcessingBatchById(this.batchId);
      }
    });
  }

  public ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getName()) {
        case BatchDetailTemplate.HEADER:
          this.headerTemplate = item.template;
          break;
        default:
          break;
      }
    });
  }

  public onPageOrSortChange(_: PageChangeEvent): void {
    this.getParkingTicketsForBatch();
  }

  private getParkingTicketsForBatch(): void {
    this.batchDetailService.getPaginatedParkingTicketsForBatch(this.batchId, this.page, this.size);
  }

  public openExportModal(): void {
    this.stiiltDialogService.show(ExportCsvModalComponent);
    this.stiiltDialogService
      .onSuccess<CsvExport>()
      .pipe(untilDestroyed(this))
      .subscribe((result: DialogResult<CsvExport>) => {
        // FIXME: Very dirty
        this.processingBatchService.getPaginatedParkingTicketsForBatch(
          this.batchId,
          Constants.DEFAULT_FIRST_PAGE_NUMBER,
          999,
        );
        this.processingBatchService.parkingTickets$.pipe(untilDestroyed(this)).subscribe((response) => {
          this.processingBatchUtilsService.exportBatchAsCsv(this.processingBatch!.uuid, this.entities, result.data!);
        });
      });
  }

  get getHowManyNotProcessed(): number {
    return this.entities.filter((ticket) => ticket.requiresInput).length;
  }

  public handleManualInput(event: MouseEvent, pt: ParkingTicket): void {
    event?.stopPropagation();
    this.stiiltDialogService.show(
      PatchParkingTicketModalFormComponent,
      {
        data: {
          parkingTicket: pt,
          batchService: this.batchDetailService,
        },
      },
      DialogWidth.LARGE,
    );
    this.stiiltDialogService
      .onSuccess<{
        isParkingTicketUpdate: boolean;
        wasProcessingBatchAlsoDeleted: boolean;
        isParkingTicketDeletion: boolean;
      }>()
      .pipe(untilDestroyed(this))
      .subscribe(
        (
          result: DialogResult<{
            isParkingTicketUpdate: boolean;
            wasProcessingBatchAlsoDeleted: boolean;
            isParkingTicketDeletion: boolean;
          }>,
        ) => {
          const { isParkingTicketDeletion, wasProcessingBatchAlsoDeleted, isParkingTicketUpdate } = result.data || {};

          if (isParkingTicketDeletion || isParkingTicketUpdate) {
            if (isParkingTicketDeletion && wasProcessingBatchAlsoDeleted) {
              this.navigateToCurrentBatches();
            } else {
              const messageKey = isParkingTicketDeletion
                ? 'current_batch.parking_ticket_deleted'
                : 'current_batch.parking_ticket_updated';
              this.toastService.success(this.translateService.translate(messageKey));
            }
            this.getParkingTicketsForBatch();
            this.batchDetailService.getProcessingBatchById(this.batchId);
          }

          this.batchDetailService.getAllPaginatedProcessingBatches(
            Constants.DEFAULT_FIRST_PAGE_NUMBER,
            Constants.DEFAULT_PAGE_SIZE,
          );
        },
      );
  }

  private navigateToCurrentBatches(): void {
    if (this.isAdmin) {
      void this.router.navigate(['/', 'app', 'administration', 'batches']);
    } else {
      void this.router.navigate(['/', 'app', 'current-batches']);
    }
    this.toastService.success(this.translateService.translate('current_batch.warnings.batch_also_deleted'));
  }

  public onRowSelect(pt: ParkingTicket) {
    this.sidebarVisible = true;
    this.clickedParkingTicket = pt;
  }

  private getProcessingBatchPayment(): ProcessingBatchPayment | undefined {
    return this.processingBatch?.processingBatchPayments.find(
      (b) => b.type === ProcessingBatchPaymentTypeEnum.STANDARD,
    );
  }

  get processedAndPaidForThisBatch(): boolean {
    return (
      !!this.processingBatch?.processedAt &&
      (this.isBatchPaid ||
        this.isBatchPaidByCardAndPaidLater ||
        this.isBatchPaidByUserBalance ||
        this.isBatchPaidByUserBalanceAndPaidLater)
    );
  }

  get completelyProcessed(): boolean {
    return !!this.processingBatch?.processedAt && this.processingBatch?.completelyProcessed;
  }

  get canDisplayParkingTickets(): boolean {
    return this.isAdmin || this.processedAndPaidForThisBatch || !this.completelyProcessed;
  }

  get isBatchPaid(): boolean {
    return (
      this.batchPayment !== undefined && this.batchPayment.paymentStatus === ProcessingBatchPaymentStatus.SUCCEEDED
    );
  }

  get isBatchPaidByUserBalance(): boolean {
    return (
      this.batchPayment !== undefined &&
      this.batchPayment.paymentMethodType === PaymentMethodType.BANK_TRANSFER &&
      !this.batchPayment.billedOn
    );
  }

  get isBatchPaidByCardAndPaidLater(): boolean {
    return (
      this.batchPayment !== undefined &&
      !!this.batchPayment.billedOn &&
      this.batchPayment.paymentMethodType === PaymentMethodType.CARD
    );
  }

  get isBatchPaidByUserBalanceAndPaidLater(): boolean {
    return (
      this.batchPayment !== undefined &&
      !!this.batchPayment.billedOn &&
      this.batchPayment.paymentMethodType === PaymentMethodType.BANK_TRANSFER
    );
  }

  shouldDisplayPaymentButton(): boolean {
    return (
      this.currentBatchState === BatchStates.PROCESSED_AND_WILL_BE_PAID_BY_CARD_AND_LATER &&
      this.isPaidLaterDatePassed()
    );
  }

  shouldDisplayInvoiceButton(): boolean {
    if (this.isAdmin) return false;
    return (
      (this.currentBatchState === BatchStates.PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE_AND_LATER &&
        this.isPaidLaterDatePassed()) ||
      this.currentBatchState === BatchStates.PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE ||
      this.currentBatchState === BatchStates.PROCESSED_AND_PAID
    );
  }

  isPaidLaterDatePassed(): boolean {
    return !!this.batchPayment?.billedOn && moment(new Date()).isSameOrAfter(moment(this.batchPayment?.billedOn));
  }

  setCurrentBatchState(): void {
    if (!this.processingBatch?.processedAt) {
      this.currentBatchState = BatchStates.NOT_PROCESSED;
    } else if (this.isBatchPaid) {
      this.currentBatchState = BatchStates.PROCESSED_AND_PAID;
    } else if (this.isBatchPaidByCardAndPaidLater) {
      this.currentBatchState = BatchStates.PROCESSED_AND_WILL_BE_PAID_BY_CARD_AND_LATER;
    } else if (this.isBatchPaidByUserBalance) {
      this.currentBatchState = BatchStates.PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE;
    } else if (this.isBatchPaidByUserBalanceAndPaidLater) {
      this.currentBatchState = BatchStates.PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE_AND_LATER;
    } else if (!this.processingBatch?.completelyProcessed) {
      this.currentBatchState = BatchStates.PROCESSED_BUT_WITH_ERRORS;
    } else if (isNil(this.batchPayment)) {
      this.currentBatchState = BatchStates.NOT_PROCESSED;
    } else {
      this.currentBatchState = BatchStates.PROCESSED_AND_NOT_PAID;
    }
  }

  private retrieveClientSecretIfApplicable() {
    this.isClientSecretError = false;
    if (
      this.currentBatchState === BatchStates.PROCESSED_AND_NOT_PAID &&
      !this.waitingAfterPayment &&
      !this.isAdmin &&
      !this.isBankTransfer
    ) {
      this.clientSecret = undefined;
      this.processingBatchService
        .createCheckoutSession(this.batchId)
        .pipe(
          catchError((e) => {
            this.isLoading = false;
            this.isClientSecretError = true;
            throw e;
          }),
        )
        .subscribe((response) => {
          this.clientSecret = response.clientSecret;
          this.isLoading = false;
        });
    }
  }

  public handlePaymentSuccess(): void {
    this.waitingAfterPayment = true;
    this.batchDetailService.getProcessingBatchById(this.batchId);

    const ref = setInterval(() => {
      if (
        [ProcessingBatchPaymentStatus.REQUIRES_CAPTURE, ProcessingBatchPaymentStatus.SUCCEEDED].includes(
          this.batchPayment!.paymentStatus!,
        )
      ) {
        this.waitingAfterPayment = false;
        clearInterval(ref);
      } else {
        this.batchDetailService.getProcessingBatchById(this.batchId);
        this.batchDetailService.getAllPaginatedProcessingBatches(
          Constants.DEFAULT_FIRST_PAGE_NUMBER,
          Constants.DEFAULT_PAGE_SIZE,
        );
      }
    }, 5000);
  }

  public handleRetryPayment(): void {
    this.isLoading = true;
    this.retrieveClientSecretIfApplicable();
  }

  public handlePaymentProofClick(row: ParkingTicket, $event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (row.paymentProofPath) {
      const completeLink = this.filePathService.getBucketPath(row.paymentProofPath);
      window.open(completeLink, '_blank');
    } else {
      this.toastService.info(this.translateService.translate('parking_tickets.toast.payment_proof_not_available'));
    }
  }

  public triggerPayment(): void {
    this.stiiltDialogService.show(
      PayLaterModalComponent,
      {
        data: {
          batch: this.processingBatch,
          batchState: this.currentBatchState,
        },
      },
      DialogWidth.LARGE,
    );
  }

  public triggerInvoiceCreationOrGetInvoice(): void {
    if (this.isBatchPaid || !!this.batchPayment?.invoiceId) {
      this.processingBatchService.getInvoice(this.batchId).subscribe((response) => {
        window.open(response.invoiceUrl, '_self');
      });
    } else {
      this.triggerPayment();
    }
  }
}

export enum BatchStates {
  NOT_PROCESSED = 'NOT_PROCESSED',
  PROCESSED_BUT_WITH_ERRORS = 'PROCESSED_BUT_WITH_ERRORS',
  PROCESSED_AND_NOT_PAID = 'PROCESSED_AND_NOT_PAID',
  PROCESSED_AND_PAID = 'PROCESSED_AND_PAID',
  PROCESSED_AND_WILL_BE_PAID_BY_CARD_AND_LATER = 'PROCESSED_AND_WILL_BE_PAID_BY_CARD_AND_LATER',
  PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE = 'PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE',
  PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE_AND_LATER = 'PROCESSED_AND_WILL_BE_PAID_BY_USER_BALANCE_AND_LATER',
}

export enum BatchDetailTemplate {
  HEADER = 'header',
}
