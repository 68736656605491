import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@core/services/env.service';
import { TranslocoService } from '@jsverse/transloco';
import { Organisation } from '@pages/account/models/organisation.model';
import { User } from '@pages/account/models/user.model';
import { IProcessingBatchService } from '@shared/interfaces/IbatchService.interface';
import { PaginatedDto, ProcessingBatchParkingTicketListResponseDto } from '@shared/models/pagination.model';
import { ParkingTicketConfirmation } from '@shared/models/parking-ticket/parking-ticket-confirmation';
import { ParkingTicket } from '@shared/models/parking-ticket/parking-ticket.model';
import { ProcessingBatch } from '@shared/models/parking-ticket/processing-batch.model';
import { QueryFilter } from '@shared/models/query-filter.model';
import { StatusEventsService } from '@shared/services/status-events.service';
import { ToastService } from '@shared/services/toast.service';
import { catchError, EMPTY, map, Observable, ReplaySubject } from 'rxjs';
import { GlobalSearch } from '@shared/models/global-search.model';
import { JobStatus } from '@admin/types/job-status.type';
import { ToggleAdminStatus } from '@shared/models/user/toggle-admin-status.model';
import { OrganisationDto } from '@admin/pages/dto/organisation.dto';
import { UserDto } from '@admin/pages/dto/user.dto';
import { CreatePaymentPlanDto } from '@admin/pages/dto/create-payment-plan.dto';
import { ServiceInstanceId } from '@shared/services/service-instance-id';
import { EventDto } from '@admin/pages/dto/event.dto';
import { VehicleAssignment, VehicleAssignmentDto } from '@shared/models/vehicle-assignment/vehicle-assignment.model';

@Injectable({
  providedIn: 'root',
})
export class AdminHttpService extends ServiceInstanceId implements IProcessingBatchService {
  public stripeInvoiceUrl = `${this.env.urls.stripeUrl}invoices/`;

  private readonly baseUrl = `${this.env.baseUrl}/admin`;
  private globalSearchResult = new ReplaySubject<GlobalSearch>(1);
  public globalSeachResult$ = this.globalSearchResult.asObservable();
  private organisations = new ReplaySubject<PaginatedDto<Organisation>>(1);
  public organisations$ = this.organisations.asObservable();

  private users = new ReplaySubject<PaginatedDto<User>>(1);
  public users$ = this.users.asObservable();

  private selectedUser = new ReplaySubject<User>(1);
  public selectedUser$ = this.selectedUser.asObservable();

  private processingBatches = new ReplaySubject<PaginatedDto<ProcessingBatch>>(1);
  public processingBatches$ = this.processingBatches.asObservable();

  private selectedProcessingBatch = new ReplaySubject<ProcessingBatch>(1);
  public selectedProcessingBatch$ = this.selectedProcessingBatch.asObservable();

  private parkingTickets = new ReplaySubject<ProcessingBatchParkingTicketListResponseDto>(1);
  public parkingTickets$ = this.parkingTickets.asObservable();

  private parkingTicketsToPayToday = new ReplaySubject<ParkingTicket[]>(1);
  public parkingTicketsToPayToday$ = this.parkingTicketsToPayToday.asObservable();

  private events = new ReplaySubject<PaginatedDto<EventDto>>(1);
  public events$ = this.events.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvService,
    private readonly toastService: ToastService,
    private readonly statusEventService: StatusEventsService,
    private readonly translateService: TranslocoService,
  ) {
    super();
  }

  getDuplicatedDrivers(batchId: number, parkingTicketId: number): Observable<VehicleAssignmentDto[]> {
    return this.http
      .get<
        VehicleAssignmentDto[]
      >(`${this.env.baseUrl}/processing-batch/${batchId}/parking-tickets/${parkingTicketId}/drivers`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  public searchEverything(search: string): void {
    this.http
      .get<GlobalSearch>(`${this.baseUrl}`, {
        params: { search },
      })
      .pipe(
        map((response: GlobalSearch) => {
          this.globalSearchResult.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getOrganisations(query: QueryFilter<Organisation>): void {
    this.http
      .get<PaginatedDto<Organisation>>(`${this.baseUrl}/organisations`, {
        params: { ...query },
      })
      .pipe(
        map((response: PaginatedDto<Organisation>) => {
          this.organisations.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getUsersByOrganisationId(organisationId: number, query: QueryFilter<User>): Observable<PaginatedDto<User>> {
    return this.http
      .get<PaginatedDto<User>>(`${this.baseUrl}/organisations/${organisationId}/users`, { params: { ...query } })
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getUserById(userId: number): void {
    this.http
      .get<User>(`${this.baseUrl}/users/${userId}`)
      .pipe(
        map((response: User) => {
          this.selectedUser.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getAllPaginatedProcessingBatches(page: number, size: number): void {
    this.http
      .get<PaginatedDto<ProcessingBatch>>(`${this.baseUrl}/batches`, { params: { page, size } })
      .pipe(
        map((response: PaginatedDto<ProcessingBatch>) => {
          this.processingBatches.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getAllPaginatedForUser(page: number, size: number, userId: number): void {
    this.http
      .get<PaginatedDto<ProcessingBatch>>(`${this.baseUrl}/users/${userId}/batches`, { params: { page, size } })
      .pipe(
        map((response: PaginatedDto<ProcessingBatch>) => {
          this.processingBatches.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getProcessingBatchById(batchId: number): void {
    this.http
      .get<ProcessingBatch>(`${this.baseUrl}/batches/${batchId}`)
      .pipe(
        map((response: ProcessingBatch) => {
          this.selectedProcessingBatch.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getPaginatedParkingTicketsForBatch(batchId: number, page: number, size: number): void {
    this.http
      .get<ProcessingBatchParkingTicketListResponseDto>(`${this.baseUrl}/batches/${batchId}/parking-tickets`, {
        params: { page, size },
      })
      .pipe(
        map((response: ProcessingBatchParkingTicketListResponseDto) => {
          this.parkingTickets.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getParkingTicketsToPayToday(): void {
    this.http
      .get<ParkingTicket[]>(`${this.baseUrl}/parking-tickets-to-pay-today`)
      .pipe(
        map((response: ParkingTicket[]) => {
          this.parkingTicketsToPayToday.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public updateParkingTicket(
    batchId: number,
    parkingTicketId: number,
    data: ParkingTicketConfirmation,
  ): Observable<void> {
    return this.http
      .patch<void>(`${this.baseUrl}/parking-tickets/${batchId}/${parkingTicketId}`, data)
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteParkingTicket(batchId: number, parkingTicketId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/processing-batch/${batchId}/${parkingTicketId}`);
  }

  public processBatch(batchId: number): void {
    this.http
      .put<{ previousJobStatus: JobStatus | 'stuck' }>(`${this.baseUrl}/batches/${batchId}/process`, {})
      .pipe(
        map((response) => {
          this.toastService.success(
            this.translateService.translate('app.admin.processing_batch.processing_success', {
              status: response.previousJobStatus,
            }),
          );
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public payToState(): void {
    this.http
      .put<void>(`${this.baseUrl}/pay-to-state/now`, {})
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe(() => {
        this.toastService.success(this.translateService.translate('app.admin.parking_ticket_payment.payment_success'));
      });
  }

  public toggleAdmin(userId: number, data: ToggleAdminStatus): Observable<User> {
    return this.http.put<User>(`${this.baseUrl}/users/${userId}/toggle-admin`, data).pipe(
      map((payload) => {
        this.toastService.success(
          this.translateService.translate(data.isAdmin ? 'users.admin_status_added' : 'users.admin_status_removed'),
        );
        return payload;
      }),
      catchError(this.handleError.bind(this)),
    );
  }

  public createOrganisation(organisation: OrganisationDto, user: UserDto): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrl}/organisations`, { organisation, user })
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteUser(user: User): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/users/${user.id}`, {}).pipe(catchError(this.handleError.bind(this)));
  }

  public addUserToOrganisation(organisationId: number, user: UserDto): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrl}/organisations/${organisationId}/add-user`, { ...user })
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createCustomPaymentPlan(organisationId: number, plan: CreatePaymentPlanDto): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrl}/organisations/${organisationId}/payment-plan`, { ...plan })
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getEvents(page: number, size: number): void {
    this.http
      .get<PaginatedDto<EventDto>>(`${this.baseUrl}/events`, { params: { page, size } })
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe((result) => {
        this.events.next(result);
      });
  }

  public validateEvent(id: number): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/events/${id}`, {}).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse, _: any, errorMessage = ''): Observable<never> {
    switch (error?.error?.errorCode) {
      default: {
        this.statusEventService.setHttpStatus(false);
        this.toastService.error(this.translateService.translate('app.errors.generic_network_error'));
        return EMPTY;
      }
    }
  }

  public redirectToInvoice(invoiceId: string) {
    window.open(`${this.stripeInvoiceUrl}${invoiceId}`, '_blank');
  }
}
