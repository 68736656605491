<stiilt-dialog
  [title]="(isMonthlyExport ? 'current_batch.export_csv_modal.title_monthly' : 'current_batch.export_csv_modal.title') | transloco"
  (actionButton)="handleActionButton()"
  [disableActionButton]="!ptSelectedFields?.length">
  <div *ngIf="isMonthlyExport">
    <p-messages [value]="warningMessage" [enableService]="false" [closable]="false" styleClass="pb-6"/>
    <div class="field w-full">
      <span class="p-float-label">
            <p-dropdown
              inputId="date"
              styleClass="w-full"
              class="w-full"
              [options]="dates"
              [(ngModel)]="selectedDate"
              [placeholder]="'current_batch.select_period' | transloco"/>
            <label for="date">{{ 'current_batch.which_period' | transloco }}</label>

          </span>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-4">
    <div *ngFor="let field of additionalAvailableFieldsToExportEntries" class="field-checkbox">
      @let key = field[0] ;
      @let value = field[1] ;
      <p-checkbox [label]="value" name="group" [value]="key"
                  [(ngModel)]="additionalSelectedFields"></p-checkbox>
    </div>
    <div *ngFor="let field of defaultFields" class="field-checkbox">
      <p-checkbox [label]="'parking_tickets.' + field | transloco" name="group" [value]="field"
                  [(ngModel)]="ptSelectedFields"></p-checkbox>
    </div>
  </div>
</stiilt-dialog>
