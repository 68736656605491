import { Component, ViewChild } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcessingBatch } from '@shared/models/parking-ticket/processing-batch.model';
import { CdkStepper } from '@angular/cdk/stepper';
import { InvisibleStepperComponent } from '@shared/components/invisible-stepper/invisible-stepper.component';
import { ProcessingBatchHttpService } from '@shared/services/processing-batch-http.service';
import { ToastService } from '@shared/services/toast.service';
import { TranslocoService } from '@jsverse/transloco';
import { StatusEventsService } from '@shared/services/status-events.service';
import { Observable } from 'rxjs';
import { ProcessingBatchPaymentInformationSSEMessage, SseMessage } from '@shared/models/sse-message.model';
import { ProcessingBatchPaymentStatus } from '@shared/types/payment.status.enum';
import { Constants } from '@shared/constants/constants';
import { ProcessingBatchPaymentTypeEnum } from '@shared/types/processing-batch-payment-type.enum';
import { ProcessingBatchPayment } from '@shared/models/parking-ticket/processing-batch-payment';
import { data } from 'autoprefixer';
import { percentage } from '@angular/fire/storage';
import { BatchStates } from '@shared/components/batch-list/batch-detail/batch-detail.component';

@Component({
  selector: 'stiilt-pay-later-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './pay-later-modal.component.html',
  styles: ``,
})
export class PayLaterModalComponent {
  @ViewChild(CdkStepper) public stepper!: InvisibleStepperComponent;

  public processingBatch!: ProcessingBatch;
  public processingBatchState!: BatchStates;
  public clientSecret!: string;
  protected paymentDetailsSubscription?: Observable<SseMessage<ProcessingBatchPaymentInformationSSEMessage>>;
  private batchPayment?: ProcessingBatchPayment;

  constructor(
    private readonly processingBatchHttpService: ProcessingBatchHttpService,
    private readonly dynamicDialogConfig: DynamicDialogConfig<{
      batch: ProcessingBatch;
      batchState: BatchStates;
    }>,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly translateService: TranslocoService,
    public readonly statusEventService: StatusEventsService,
  ) {
    this.processingBatch = this.dynamicDialogConfig.data?.batch!;
    this.processingBatchState = this.dynamicDialogConfig.data?.batchState!;
  }

  public getCurrentStepIdentifier(): string {
    const currentStepIndex = this.stepper?.selectedIndex;
    return this.stepper?.steps.get(currentStepIndex)!.identifier!;
  }

  private nextStep(): void {
    this.stepper.next();
  }

  public previousStep(): void {
    this.stepper.previous();
  }

  public handleBackButton(): void {
    this.previousStep();
  }

  public handleActionButton(): void {
    this.subscribeToPaymentDetailsSSE();
    this.nextStep();
    this.processingBatchHttpService.aojojo(this.processingBatch?.id!);
  }

  private subscribeToPaymentDetailsSSE(): void {
    this.paymentDetailsSubscription = this.processingBatchHttpService.subscribeToPaymentDetails(
      this.processingBatch?.uuid!,
      this.processingBatch?.id!,
    );
    this.paymentDetailsSubscription.subscribe({
      next: (paymentInfo: SseMessage<ProcessingBatchPaymentInformationSSEMessage>) => {
        if (paymentInfo.data?.invoiceUrl) {
          this.dynamicDialogRef.close(true);
          window.open(paymentInfo.data?.invoiceUrl, '_self');
        }
        if (paymentInfo.data?.clientSecret) {
          this.clientSecret = paymentInfo.data?.clientSecret!;
          this.nextStep();
        }
      },
      error: (error: SseMessage<ProcessingBatchPaymentInformationSSEMessage>) => {
        // Handle the error
        console.error(error);
      },
      complete: () => {
        // Handle the completion
      },
    });
  }

  protected readonly PayLaterSteps = PayLaterSteps;

  public handlePaymentSuccess(): void {
    this.stepper.next();
    this.processingBatchHttpService.selectedProcessingBatch$.subscribe((batch) => {
      this.batchPayment = batch.processingBatchPayments.find(
        (payment) => payment.type === ProcessingBatchPaymentTypeEnum.STANDARD,
      );
    });
    this.processingBatchHttpService.getProcessingBatchById(this.processingBatch.id);

    const ref = setInterval(() => {
      if (
        [ProcessingBatchPaymentStatus.REQUIRES_CAPTURE, ProcessingBatchPaymentStatus.SUCCEEDED].includes(
          this.batchPayment!.paymentStatus!,
        )
      ) {
        clearInterval(ref);
        this.dynamicDialogRef.close(true);
      } else {
        this.processingBatchHttpService.getProcessingBatchById(this.processingBatch.id);
        this.processingBatchHttpService.getAllPaginatedProcessingBatches(
          Constants.DEFAULT_FIRST_PAGE_NUMBER,
          Constants.DEFAULT_PAGE_SIZE,
        );
      }
    }, 3000);
  }

  protected readonly data = data;
  protected readonly percentage = percentage;
  protected readonly Math = Math;
}

export enum PayLaterSteps {
  RECAP = 'RECAP',
  PT_CHECK = 'PT_CHECK',
  PAYMENT = 'PAYMENT',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
}
