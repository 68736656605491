export enum ORDER_BY_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}
export class Constants {
  public static readonly DEFAULT_FIRST_PAGE_NUMBER = 1;
  public static readonly DEFAULT_PAGE_SIZE = 10;
  static DEFAULT_ORDER_BY_DIRECTION: ORDER_BY_DIRECTION = ORDER_BY_DIRECTION.ASC;
  static DEFAULT_BREAKPOINT: number = 1024;
  public static readonly DEFAULT_ANNUAL_RATE = 0.8;
}
