import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/models/controls-of';
import { ParkingTicket } from '@shared/models/parking-ticket/parking-ticket.model';
import { ParkingTicketConfirmation } from '@shared/models/parking-ticket/parking-ticket-confirmation';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileService } from '@shared/services/file.service';
import {
  ColorBadgeStatusColor,
  ColorBadgeStatusComponent,
} from '@shared/components/color-badge-status/color-badge-status.component';
import { TooltipOptions } from 'primeng/api';
import { DialogComponent } from '@shared/components/dialog-wrapper/dialog.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { PrimengModule } from '@shared/primeng.module';
import { StiiltCalendarComponent } from '@shared/components/calendar/stiilt-calendar.component';
import { EmbeddedPdfViewerComponent } from '@shared/components/embedded-pdf-viewer/embedded-pdf-viewer.component';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { StiiltTemplateDirective } from '@shared/directives/stiilt-template.directive';
import { IProcessingBatchService } from '@shared/interfaces/IbatchService.interface';
import { ManualInputReason } from '@shared/types/manual-input-reason.type';
import { VehicleAssignmentDto } from '@shared/models/vehicle-assignment/vehicle-assignment.model';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';

@Component({
  standalone: true,
  imports: [
    DialogComponent,
    ColorBadgeStatusComponent,
    TranslocoPipe,
    PrimengModule,
    ReactiveFormsModule,
    StiiltCalendarComponent,
    EmbeddedPdfViewerComponent,
    NgIf,
    StiiltTemplateDirective,
    NgClass,
    RadioButtonModule,
    DatePipe,
    TranslocoDatePipe,
  ],
  templateUrl: './patch-parking-ticket-modal-form.component.html',
  styles: ``,
})
export class PatchParkingTicketModalFormComponent {
  public parkingTicketConfirmationForm!: FormGroup<ControlsOf<ParkingTicketConfirmation>>;
  public parkingTicket: ParkingTicket;
  public duplicateDrivers: VehicleAssignmentDto[] = [];

  protected readonly ColorBadgeStatusColor = ColorBadgeStatusColor;
  protected readonly ManualInputReason = ManualInputReason;

  public tooltipOptions: TooltipOptions = {
    tooltipStyleClass: 'hint-tooltip',
  };

  private batchService!: IProcessingBatchService;

  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig<{
      parkingTicket: ParkingTicket;
      batchService: IProcessingBatchService;
    }>,
    public readonly dynamicDialogRef: DynamicDialogRef,
    private readonly fileService: FileService,
  ) {
    this.parkingTicket = dynamicDialogConfig.data?.parkingTicket!;
    this.batchService = dynamicDialogConfig.data?.batchService!;
    this.buildForm(this.parkingTicket);
    if (this.parkingTicket.manualInputReason === ManualInputReason.SEVERAL_DRIVERS_FOUND) {
      this.batchService
        .getDuplicatedDrivers(this.parkingTicket.processingBatchId, this.parkingTicket.id!)
        .subscribe((vehicleAssignments: VehicleAssignmentDto[]) => {
          this.duplicateDrivers = vehicleAssignments;
          this.parkingTicketConfirmationForm.controls.vehicleAssignmentId.addValidators(Validators.required);
        });
    }
  }

  private buildForm(parkingTicket: ParkingTicket) {
    this.parkingTicketConfirmationForm = new FormGroup<ControlsOf<ParkingTicketConfirmation>>({
      amount: new FormControl(parkingTicket?.amount, Validators.required),
      brand: new FormControl(parkingTicket?.vehicleBrand, Validators.required),
      licencePlate: new FormControl(parkingTicket?.licencePlate, Validators.required),
      paymentNotice: new FormControl(parkingTicket?.paymentNotice, Validators.required),
      paymentKey: new FormControl(parkingTicket?.paymentKey, Validators.required),
      offenceDate: new FormControl(parkingTicket?.offenceDate as string, Validators.required),
      vehicleAssignmentId: new FormControl({
        disabled: parkingTicket.manualInputReason !== ManualInputReason.SEVERAL_DRIVERS_FOUND,
        value: null,
      }),
    });
  }

  public handleOnDelete(event: MouseEvent): void {
    this.batchService
      .deleteParkingTicket(this.parkingTicket.processingBatchId, this.parkingTicket.id!)
      .subscribe((result: boolean) => {
        this.dynamicDialogRef.close({
          wasProcessingBatchAlsoDeleted: result,
          isParkingTicketDeletion: true,
        });
      });
  }

  public handleOnSave(): void {
    this.batchService
      .updateParkingTicket(
        this.parkingTicket.processingBatchId,
        this.parkingTicket.id!,
        this.parkingTicketConfirmationForm.value as ParkingTicketConfirmation,
      )
      .subscribe(() => {
        this.dynamicDialogRef.close({ isParkingTicketUpdate: true });
      });
  }

  public getPdfUrl(): string {
    return this.fileService.getBucketPath(this.parkingTicket?.path!);
  }

  public getAssignmentDriverData(vehicleAssignment: VehicleAssignmentDto) {
    if (vehicleAssignment.driverEmail) {
      return `${vehicleAssignment.driverEmail}`;
    } else if (vehicleAssignment.driverReference) {
      return vehicleAssignment.driverReference;
    } else {
      return `${vehicleAssignment.driverName} ${vehicleAssignment.driverFirstName ? ' - ' + vehicleAssignment.driverFirstName : ''}`;
    }
  }
}
